import { DateTime } from 'luxon'

function numberWord (number, zero) {
  number = parseInt(number, 10)
  var map = {0: zero, 1: 'one', 2: 'two', 3: 'three', 4: 'four', 5: 'five', 6: 'six', 7: 'seven', 8: 'eight', 9: 'nine', 10: 'ten', 11: 'eleven', 12: 'twelve', 13: 'thirteen', 14: 'fourteen', 15: 'fifteen', 16: 'sixteen', 17: 'seventeen', 18: 'eighteen', 19: 'nineteen'}
  var tenMap = {2: 'twenty', 3: 'thirty', 4: 'fourty', 5: 'fifty', 6: 'sixty', 7: 'seventy', 8: 'eighty', 9: 'ninety'}
  if (number in map) {
    return map[number]
  }
  var digits = number.toString()
  var ones = numberWord(digits[digits.length - 1], '')
  var output = tenMap(digits[digits.length - 2])
  if (ones) {
    output += ' ' + ones
  }
  return output
}

function updateTime () {
  var now = DateTime.local().setZone('Pacific/Auckland')
  var hour = (parseFloat(window.timeRange.value) + now.hour + now.minute / 60 + now.second / 3600) % 24
  var offset = parseFloat(window.timeRange.value)
  now = now.plus({hours: offset})
  if (offset === 0) {
    window.time.innerHTML = "It's "
  } else {
    var formattedOffset = Math.abs(offset) + ' ' + ((Math.abs(offset) === 1) ? 'hour' : 'hours')
    if (offset > 0) {
      window.time.innerHTML = 'In ' + formattedOffset + " it'll be "
    } else {
      window.time.innerHTML = formattedOffset + ' ago it was '
    }
  }
  var closestHour = now.plus({minutes: 30})
  if (closestHour.minute < 15) {
    window.time.innerHTML += ' coming up to '
  } else if (closestHour.minute < 30) {
    window.time.innerHTML += ' nearly '
  } else if (closestHour.minute < 45) {
    window.time.innerHTML += ' just past '
  } else {
    window.time.innerHTML += ' some time after '
  }
  window.time.innerHTML += '<strong title="' + now.toFormat('cccc, h:mm a') + '">' + closestHour.toFormat('ha').toLowerCase() + ' on ' + closestHour.toFormat('cccc') + '</strong>.'
  if (hour < 6) {
    hour += 24
  }
  hour = hour - 6
  var day = hour < 12
  if (!day) {
    hour = hour - 12
  }
  hour = hour / 12

  if (day) {
    window.box.className = (hour < 0.5) ? 'morning' : 'afternoon'
  } else {
    window.box.className = (hour < 0.5) ? 'evening' : 'night'
  }

  // window.time.innerHTML += '<br>' + parseInt(hour * 100, 10) + '% through ' + (day ? 'day' : 'night')

  window.sun.style.display = day ? 'block' : 'none'
  window.moon.style.display = !day ? 'block' : 'none'
  window.sun.style.top = parseInt(100 - hour * 100, 10) + '%'
  var base = Math.pow(hour, 2)
  var right = parseInt(base * 26, 10) + 3
  window.sun.style.right = right + '%'
  window.moon.style.top = parseInt(100 - hour * 100, 10) + '%'
  window.moon.style.right = right + '%'
}
window.timeRange.addEventListener('input', updateTime)

function doUpdateTime () {
  updateTime()
  setTimeout(doUpdateTime, 60000)
}
doUpdateTime()
window.timeRange.style.display = ''

var dateWords = document.querySelectorAll('.year-word')
for (var i = 0; i < dateWords.length; i++) {
  var el = dateWords[i]
  var year = DateTime.local().setZone('Pacific/Auckland').diff(DateTime.fromISO(el.getAttribute('data-date')), 'years').years
  el.innerText = numberWord(year, 'zero')
}
